import { $, $$, insertHTML, remove } from '@/3pc/js/utils/dom';
import { cookieBannerTemplate } from './cookiebanner-template';
import './cookiebanner.scss';

export default class CookieBanner {
  constructor(props) {
    this.cookieName = props.cookieName;
    this.cookieDuration = props.cookieDuration;
    this.trackings = props.trackings;
  }

  renderCookiebanner = () => {
    insertHTML($('body'), 'beforeend', cookieBannerTemplate);

    this.$banner = $('.cookiebanner');
    this.$cookieButtons = $$('.close-cookiebanner', this.$banner);

    this.bindEvents();

    /*
     * Focus first title of cookiebanner.
     * So for the users, who has to use the keyboard to navigate trough the page
     * can remove the cookiebanner right away at the beginning (accessibillity).
     */
    $('#cookiebanner_label') && $('#cookiebanner_label').focus();
  };

  createCookie = (cookieName, cookieValue, cookieDuration) => {
    let expires = '';
    const days = cookieDuration;

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    } else {
      expires = '';
    }

    document.cookie = `${cookieName}=${cookieValue}${expires}; path=/; samesite=strict`;
  };

  checkCookie = cookieName => {
    const nameEQ = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  createTrackingScripts = () => {
    if (this.trackings.length) {
      this.trackings.forEach(tracking => {
        const insertSelector = tracking.insertSelector;

        if (tracking.scriptLink) {
          this.createScriptLink('script', tracking.scriptLink, insertSelector);
        }

        if (tracking.script) {
          this.createScriptTag('script', tracking.script, insertSelector);
        }
      });
    }
  };

  createScriptTag = (tagType, script, insertSelector) => {
    const newScript = document.createElement(tagType);
    const inlineScript = document.createTextNode(script);

    newScript.appendChild(inlineScript);
    $(insertSelector).appendChild(newScript);
  };

  createScriptLink = (tagType, scriptLink, insertSelector) => {
    const newScript = document.createElement(tagType);

    newScript.src = scriptLink;
    $(insertSelector).appendChild(newScript);
  };

  bindEvents = () => {
    this.$cookieButtons.forEach(button => {
      button.addEventListener('click', () => {
        const choice = button.dataset.choice;

        this.createCookie(this.cookieName, choice, this.cookieDuration);
        if (choice === 'true') this.createTrackingScripts();
        remove(this.$banner);
      });
    });
  };

  init = () => {
    // check if cookie exists (with the users choice to be tracked or not)
    if (document.cookie.indexOf(this.cookieName) < 0) {
      // if there is none, render the cookiebanner which will ask for his choice
      this.renderCookiebanner();
    } else {
      /*
       * if the cookie already exists, check if user want to be tacked or not.
       * If yes, create the tracking scripts
       * */
      if (this.checkCookie(this.cookieName) !== 'false') {
        this.createTrackingScripts();
      }
    }
  };
}
