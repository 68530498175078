const docLang = document.documentElement.lang;
const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;
const labels = {
  agree: {
    de: 'Zustimmen',
    en: 'Accept',
  },
  reject: {
    de: 'Ablehnen',
    en: 'No thanks',
  },
  button: {
    de: 'Speichern & Schließen',
    en: 'Save & close',
  },
  guidelineTitle: {
    de: 'Cookie-Einstellungen',
    en: 'Cookie settings',
  },
  guidelineText: {
    de:
      'Diese Webseite verwendet Cookies, um bestimmte Funktionen zu aktivieren und Ihnen besseren Service zu bieten. Bitte klicken Sie auf "Zustimmen", um dafür die Verwendung Ihrer Daten zu erlauben. Alle Informationen darüber, wie wir Ihre persönlichen Daten verarbeiten und wie Sie Ihre Einstellungen jederzeit ändern können, finden Sie in unserer <a href="https://www.deutsches-museum.de/datenschutz" class="link--intern">Datenschutzerklärung</a>.',
    en:
      'This website uses cookies to activate certain functions and offer you better service. Please click "Accept" to permit the use of your data for that purpose. For information on how we process your personal data and how you can change your settings at any time, please refer to our <a href="https://www.deutsches-museum.de/datenschutz" class="link--intern">data protection statement</a>.',
  },
};

export const cookieBannerTemplate = `<section class="cookiebanner" aria-modal="true" aria-labelledby="cookiebanner_label">
    <div class="cookiebanner__inner">
        <div class="cookiebanner__wrapper">
            <div>
              <h2 id="cookiebanner_label" tabindex="0">${labels.guidelineTitle[lang]}</h2>
              <p>${labels.guidelineText[lang]}</p>
            </div>

            <div class="cookiebanner__action-wrapper">
              <button class="cookiebanner__button close-cookiebanner" data-choice="true"><span>${labels.agree[lang]}</span></button>
              <button class="cookiebanner__button cookiebanner__button--invert close-cookiebanner" data-choice="false"><span>${labels.reject[lang]}</span></button>
            </div>
        </div>
    </div>
</section>
`;
