import { $ } from '@/3pc/js/utils/dom';
import CookieBanner from '@/3pc/js/modules/cookiebanner/cookiebanner';

const cookieName = 'DM-Cookie';
new CookieBanner({
  cookieName,
  cookieDuration: 36500,
  trackings: [
    {
      insertSelector: 'body',
      script: 'var _paq = window._paq = window._paq || [];_paq.push([\'trackPageView\']);_paq.push([\'enableLinkTracking\']);(function() {var u="https://matomo.deutsches-museum.de/";_paq.push([\'setTrackerUrl\', u+\'matomo.php\']);_paq.push([\'setSiteId\', \'6\']);var d=document, g=d.createElement(\'script\'),s=d.getElementsByTagName(\'script\')[0];g.type=\'text/javascript\'; g.async=true; g.src=u+\'matomo.js\';s.parentNode.insertBefore(g,s);})();',
    },
  ],
}).init();

// TODO: set real tracking scripts

const deleteCookieBtn = $('.remove-cookie-btn');

deleteCookieBtn &&
  deleteCookieBtn.addEventListener('click', () => {
    document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    location.reload();
  });
